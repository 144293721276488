import * as React from "react";
import Layout from "../components/Layout";
import FaqDisplay from "../components/FaqDisplay";
import Seo from "../components/Seo";

const FaqPage = () => {
  return (
    <Layout>
      <Seo title="FAQs" />

      <FaqDisplay></FaqDisplay>
    </Layout>
  );
};

export default FaqPage;
