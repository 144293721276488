import React from "react";
import styled from "styled-components";
import Wrapper from "../components/styles/Wrapper";

const FaqDisplayStyles = styled.div`
  margin: 0 64px;

  h2 {
    font-size: 1.5rem;
  }
`;

const FaqDisplay = () => {
  return (
    <Wrapper>
      <FaqDisplayStyles>
        <h1>Frequently Asked Questions</h1>
        <h2>What is soft play rental?</h2>
        <p>
          Soft play is equipment designed for kids to provide a soft, safe, and
          clean environment that allows and encourages them to explore and play.
        </p>
        <h2>What is your cleaning process?</h2>
        <p>
          Our equipment (including every ball pit ball, floor mat, gate, etc.)
          is cleaned, disinfected, and sanitized after each use. In addition, we
          will disinfect one more time after we set up for your event.
        </p>
        <h2>What ages are appropriate for your equipment?</h2>
        <p>
          - Our soft play is for ages 0-4 years of age (with adult supervision).
        </p>
        <p>- Our ball pits and white bounce houses are for ALL-AGES!</p>
        <h2>How does the delivery work?</h2>
        <p>
          We will arrive 45min-1hr prior to your event start time to begin set
          up. If there will be any restrictions that may delay set up (like
          stairs, lack of parking, etc.) please let us know prior to the event
          so that we may plan accordingly.{" "}
        </p>
        <h2>Is there a delivery fee?</h2>
        <p>
          There is a delivery fee based on distance. The first 15 miles are
          free. After that we charge $1.00 per extra mile.
        </p>
        <h2>What forms of payment do you accept?</h2>
        <p>We accept all major credit cards and ACH (eCheck) bank transfers.</p>
        <h2>What is your Weather Policy?</h2>
        <p>
          We cannot set up outdoors when it is wet or raining. We will work with
          you to accommodate all the fun indoors if you have the space. If that
          is not an option, we can reschedule for a different date based upon
          availability or we will gladly refund you total paid minus the
          deposit.
        </p>
        <h2>Cancelations</h2>
        <p>
          All orders require a $50 deposit fee. This fee is fully refundable if
          you cancel your booking at least 14 days prior to your rental date. If
          you cancel between 2-13 days prior to your rental you will be given a
          raincheck that is good for 1 year.
        </p>
        <h2>Are there any rules to use the equipment?</h2>
        <p>
          Yes, we do have some rules for our equipment to ensure everyone and
          the equipment stay safe. A copy of the rules will be provided upon
          your booking, but here are a few basics to keep in mind:
        </p>
        <ul>
          <li>No FACE PAINT</li>
          <li>No FOOD or SHOES or ANIMALS in or on our equipment</li>
          <li>No toys from outside</li>
          <li>
            Soft play equipment must not get wet as it can be slippery, or not
            be used in full sun as the equipment becomes very HOT (if your set
            up area is not shaded we provide a canopy tent as an add-on)
          </li>
          <li>
            Bouncy castles – cannot be inflated on windy days (winds above 20
            MPH)
          </li>
        </ul>
      </FaqDisplayStyles>
    </Wrapper>
  );
};

export default FaqDisplay;
